import React from 'react';
import Scroll from './Scroll';

export default function Header() {
  return (
    <section id="header">
      <header className="major">
        <h1>
          <a id="title"><span style={{color: 'red'}}>ID</span>CLINICAL</a>
        </h1>
        <p>team of professionals</p>
        <p>sites activation and studies conduct in <span style={{color: 'red'}}>Poland</span></p>
      </header>
      {/*<div className="container">*/}
      {/*  <ul className="actions special">*/}
      {/*    <li>*/}
      {/*      <Scroll type="id" element="one">*/}
      {/*        <a href="#one" className="button primary">*/}
      {/*          Begin*/}
      {/*      </a>*/}
      {/*      </Scroll>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </section>
  );
}
