import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer>
      {/*<ul className="icons">*/}
      {/*  {config.socialLinks.map(social => {*/}
      {/*    const { icon, name, url } = social;*/}
      {/*    return (*/}
      {/*      <li key={url}>*/}
      {/*        <a href={url} className={`icon alt ${icon}`}>*/}
      {/*          <span className="label">{name}</span>*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</ul>*/}
      <ul className="copyright">
        <li>&copy; idclinical.com</li>
        {/*<li>*/}
        {/*  Design: <a href="http://html5up.net">HTML5 UP</a>*/}
        {/*</li>*/}
      </ul>
    </footer>
  );
}
