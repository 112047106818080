import React from 'react';
import Scrollspy from 'react-scrollspy';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import { Link } from 'gatsby';

const sections = [
  { id: 'one', bg: require('../assets/images/imgg1.jpg') },
  { id: 'two', bg: require('../assets/images/bg2.jpg') },
  { id: 'three', bg: require('../assets/images/imgg3.jpg') },
];
const IndexPage = () => (
  <Layout>
    <Header/>

    <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt=""/>
        </span>
        <div className="content">
          <header className="major">
            <h2>Who we are</h2>
          </header>
          <p>
            ID Clinical is the effective and impactful team of professionals that will help you develop and improve your medical therapies
            through the whole proficient clinical study conduct process in Poland.
          </p>
          <p>
            Our company was founded by experienced experts working in broad range of clinical trials
            in Poland. Our main profile of activities is assisting clients with full clinical study conduct
            in compliance with local regulations and support with all processes needed for sites
            activation.
          </p>
          <p>
            Although ICH GCP prescribes the general rules in EC/RA communication, there are significant differences
            among countries and our staff is ready to support you on any stage of this process or take all obligation
            in efficient communication and maintenance from early feasibility to the close-out visit.
          </p>
          <p>
            If you are thinking about outsourcing the whole study conduct process, only start–up activities, monitoring (remote or on-site) or some parts of them we are both ready and happy to assist you with this complex procedure.
          </p>
          <p>
            We are strongly aware that keeping tight timelines can reduce the study budget and be part of the great
            success of your company by receiving the new medical therapy approval.
          </p>
        </div>
        <Scroll type="id" element="two">
          <a href="#two" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="two" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt=""/>
        </span>
        <div className="content">
          <header className="major">
            <h2>Service</h2>
          </header>
          <p>
            ID Clinical`s experts service includes, but is not limited to:

          </p>
          <p>
            <ul className="list">
              <li> Clinical study design</li>
              <li> Medical writing (protocol writing, IB preparation)</li>
              <li> Medical advising</li>
              <li> eCRF</li>
              <li> Feasibility process</li>
              <li> Site Selection</li>
              <li> Pre-study visits</li>
              <li> Contract and Budget negotiations </li>
              <li> Patient Information Sheet and Informed Consent Form preparation </li>
              <li> Regulatory Authorities and Ethic Committees submission</li>
              <li> Communication and obtaining full approvals</li>
              <li> Essential Documentation preparation, collection and submitting</li>
              <li> Drug Release process</li>
              <li> Site Initiation</li>
              <li> Ongoing RA/EC submission including substantial and non-substantial amendments</li>
              <li> Full monitoring (remote or on-site)</li>
              <li> Close-out visit</li>
              <li> Biostatistics</li>
              <li> Safety reporting</li>
              <li> Filling</li>
              <li> Archiving</li>
              <li> Trainings</li>
            </ul>
          </p>
        </div>
        <Scroll type="id" element="three">
          <a href="#three" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>
    <section id="three" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[2].bg} alt=""/>
        </span>
        <div className="content">
          <header className="major">
            <h2>Therapeutic area</h2>
          </header>
          <p>
            Cardiology, Vascular Diseases Dermatology, Endocrinology, Gastroenterology, Haematology, Immunology,
            Infectious Diseases, Nephrology, Urology, Neurology, Oncology, Psychiatry, Pulmonary, Respiratory Diseases,
            Reproductive, Rheumatology, Paediatrics Diseases, Orphan Diseases and more
          </p>
        </div>
        <Scroll type="id" element="footer">
          <a href="#footer" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>



    <section id="footer">
      <div className="container">
        <header className="major">
          <h2>Get in touch</h2>
        </header>
        <a href="mailto:office@idclinical.com">
          <button className='button-email'>Send Message</button>
        </a>
          <p>Email: office@idclinical.com</p>
        <a href="tel:+48665534264">
          <button className='button-email'>Call Us</button>
        </a>
        <p>Phone: +48 665 534 264</p>
      </div>
      <Footer/>
    </section>

    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={50}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
);

export default IndexPage;
